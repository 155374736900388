:root {
  --bg-color: #111;
  --bg-light: #2a2a2a;
  --font-color: white;
  --neon-first: #ffd5ff;
  --neon-second: #d42cca;
  --glow-opacity: 0.25;
}

body {
  margin: 0;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*AUTH CSS*/

.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.authentication-input {
  color: var(--font-color);
  background: transparent;
  margin-left: 10px 0;
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(100% - 10px);
  box-sizing: border-box;
  max-width: 500px;
}

/*APP CSS*/

.App {
  text-align: center;
  background: var(--bg-color);
}

.App-header {
  background-color: var(--bg-color);
  background: var(--bg-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
}

.scroll-down {
  display: block;
  margin: -200px auto 100px;
  padding: 33px 30px;
  background-color: transparent;
  color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
  line-height: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 0vh;
}

.zmdi-chevron-down {
  font-size: 200px;
  margin: -75px 0;
}

i::before {
  margin-right: -10px;
  color: transparent;
  -webkit-text-stroke: 0.8px var(--neon-second);
  animation: glowing 1.2s reverse infinite ease-in-out;
}

i:nth-child(1)::before {
  animation-delay: 0;
}
i:nth-child(2)::before {
  animation-delay: 0.14s;
}
i:nth-child(3)::before {
  animation-delay: 0.21s;
}

/* Let's Create Arrow Animation */
@keyframes glowing {
  0% {
    opacity: 0;
  }
  100% {
    opacity: var(--glow-opacity, 0.5);
    filter: drop-shadow(0 0 0 #ffffff) drop-shadow(0 0 1px #ffffff)
      drop-shadow(0 0 2px var(--neon-first))
      drop-shadow(0 0 3px var(--neon-first))
      drop-shadow(0 0 4px var(--neon-second))
      drop-shadow(0 0 5px var(--neon-second));
  }
}

.wrapper:hover i::before {
  --glow-opacity: 1;
}

.neon {
  font-family: "Monoton", cursive;
  font-size: 130px;
  color: var(--neon-first);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 400;
  letter-spacing: 8px;
  text-shadow: 1px 0px 4px var(--neon-first), 2px 0px 4px var(--neon-first),
    3px 0px 4px var(--neon-first), 2px 0px 3px var(--neon-second),
    2px 3px 15px var(--neon-second), 2px 0px 15px, 5px 0px 125px,
    20px 0vw 200px var(--neon-second), 40px 0vw 200px var(--neon-second);
  z-index: 1;
}

.flicker-slow {
  animation: flicker 3s linear infinite;
}

.flicker-medium {
  animation: flicker 2s linear infinite;
}

.flicker-fast {
  animation: flicker 1s linear infinite;
}

@keyframes flicker {
  0%,
  19.999%,
  22%,
  62.999%,
  64%,
  64.999%,
  70%,
  100% {
    opacity: 0.99;
  }
  20%,
  21.999%,
  63%,
  63.999%,
  65%,
  69.999% {
    opacity: 0.4;
  }
}

/* Intro CSS */

.intro {
  background: var(--bg-light);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: var(--font-color);
}

.columns {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Ensure columns start at the same height */
  width: 100%;
  flex-wrap: wrap; /* Allow columns to wrap on smaller screens */
}

.column {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  columns: 1;
  flex: 1;
  padding: 10px;
  max-width: 1400px;
  box-sizing: border-box;
}

/* Contact Form CSS */

.contact-form {
  background-color: var(--bg-color); /* Light grey background */
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
}

.contact-form h2 {
  font-family: "Courier New", Courier, monospace;
  color: var(--font-color);
}

.contact-form h3 {
  font-family: "Courier New", Courier, monospace;
  color: var(--font-color);
}

.contact-form form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-columns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.form-column {
  flex: 1;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-family: "Courier New", Courier, monospace;
  color: var(--font-color);
}

.contact-form input {
  color: var(--font-color);
  background: transparent;
  margin-left: 10px 0;
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(100% - 10px);
  box-sizing: border-box;
  max-width: 500px;
}

.submit-btn {
  color: #fff;
  width: calc(100% - 10px);
  max-width: 500px;
  height: 27px;
  padding: 5px;
  border-radius: 4px;
  margin: 10px 10px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
}

.send {
  color: var(--neon-second);
  border: 1px solid var(--neon-second);
  box-shadow: 0 0 5px var(), var(--neon-second) 0 5px var(--neon-second) inset;
  z-index: 1;
}
.send:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background: var(--neon-second);
  box-shadow: 0 0 20px var(--neon-second);
  transition: all 0.3s ease;
}
.send:hover {
  color: var(--font-color);
}
.send:hover:after {
  left: 0;
  width: 100%;
}
.send:active {
  top: 2px;
}

.legend {
  font-family: "Courier New", Courier, monospace;
}

/* Promo Video CSS */

.promo-video {
  background-color: var(--bg-color); /* Light grey background */
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.promo-video h2 {
  font-family: "Courier New", Courier, monospace;
  color: var(--font-color);
}

.promo-video video {
  max-width: 100%;
  border-radius: 8px;
}

/* ProgressBar CSS */
.progress-section {
  margin: 20px 0;
  background-color: var(--bg-color);
}

.progress-steps {
  display: flex;
  justify-content: space-around;
  margin: 0 auto 10px auto;
  width: 90%;
}

.progress-section h2 {
  font-family: "Courier New", Courier, monospace;
  color: var(--font-color);
  margin-bottom: 10px;
}

.progress-bar {
  background-color: var(--bg-light);
  border-radius: 6px;
  overflow: hidden;
  height: 25px;
  padding: 4px;
  width: 90%;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  box-sizing: border-box;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25),
    0 1px rgba(255, 255, 255, 0.08);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25),
    0 1px rgba(255, 255, 255, 0.08);
  margin: 0 auto 10px auto;
}

.progress {
  box-sizing: border-box;
  background-color: var(--neon-second);
  height: 100%;
  transition: width 0.3s;
  border-radius: 4px;
}

.progress-content {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 75%;
  align-items: center;
  margin: 0 auto;
}

.progress-text {
  font-size: 1.25rem;
  padding-left: 50px;
  padding-right: 50px;
  color: var(--font-color);
  align-items: center;
}

@font-face {
  font-family: "Neon Glow";
  src: url(https://assets.codepen.io/230569/NeonGlow-8VLz.ttf);
}

.neon-card {
  --blur: 1.75rem;
  --box-blur: calc(0.5 * var(--blur));
  --glow: var(--color);
  --size: 12rem;

  align-items: center;
  border-radius: 12px;
  border: 4px solid currentColor;
  color: var(--color);
  display: inline-flex;
  flex-direction: column;
  font-family: system-ui, sans-serif;
  height: var(--size);
  justify-content: space-around;
  padding: 1rem;
  width: var(--size);
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 50px;
  filter: grayscale(100%);
  transition: filter 0.3s, box-shadow 0.3s;

  > * {
    margin: 0;
    padding: 0;
  }

  .round {
    font-weight: 700;
  }

  .symbol {
    color: var(--color);
    font-size: 4rem;
    font-family: "Neon Glow";
    text-shadow: 0 0 var(--blur) var(--glow);
  }
  
  .send {
    color: var(--color);
    border: 1px solid var(--color);
    box-shadow: 0 0 5px var(), var(--color) 0 5px var(--color) inset;
    z-index: 1;
  }
  .send:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background: var(--color);
    box-shadow: 0 0 20px var(--color);
    transition: all 0.3s ease;
  }
  .send:hover {
    color: var(--font-color);
  }
  .send:hover:after {
    left: 0;
    width: 100%;
  }
  .send:active {
    top: 2px;
  }
  

  &.gold {
    --color: #fffb7b;
    filter: grayscale(50%) saturate(175%);
    height: 22rem;
  }
  &.bronze {
    --color: #ffb92e;
    filter: grayscale(50%) brightness(110%);
    height: 22rem;
  }
  &.pink {
    --color: var(--neon-second);
    filter: grayscale(50%) brightness(125%);
  }
  &.kr {
    --color: #c4c4c6;
    filter: saturate(200%);
  }
  &.silver {
    --color: #b7e7f7;
    filter: grayscale(50%) brightness(105%);
    height: 22rem;
  }
}

.neon-card:hover {
  filter: grayscale(0%);
  box-shadow: 
   /* --- "glass" tube --- */
    /* inside */ inset 0 0 0 2px
    rgba(0, 0, 0, 0.15),
    /* outside */ 0 0 0 2px rgba(0, 0, 0, 0.15),
    /* --- glow --- */ /* inside */ inset 0 0 var(--box-blur) var(--glow),
    /* outside */ 0 0 var(--box-blur) var(--glow);
}

.prizes h2 {
  font-family: "Courier New", Courier, monospace;
  color: var(--font-color);
}

/* Media query for screens smaller than 1200px */
@media (max-width: 1200px) {
  .column {
    max-width: 600px; /* Adjust max-width for smaller screens */
  }
  .neon {
    font-size: 70px; /* Adjust font size for smaller screens */
  }
  .zmdi-chevron-down {
    font-size: 150px;
    margin: -55px 0;
  }
}

/* Media query for screens smaller than 768px */
@media (max-width: 650px) {
  .columns {
    flex-direction: column; /* Stack columns vertically on small screens */
  }
  .column {
    columns: 1;
    max-width: 100%; /* Full width for each column */
  }
  .neon {
    font-size: 60px; /* Adjust font size for smaller screens */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .neon .second-line {
    display: block;
  }
  .form-columns {
    flex-direction: column;
    justify-content: center; /* Stack form columns vertically on small screens */
  }
  .zmdi-chevron-down {
    font-size: 100px;
    margin: -35px 0;
  }
}
